import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import loadable from '@loadable/component';
import cx from 'classnames';

import { URL } from 'src/resources/constants/url';

import contact from './img/contact.svg';
import contactClose from './img/contactClose.svg';
import IconClose from './img/ic_close.svg';
import { hideSupportButton } from './action';
import styles from './styles.scss';
import ga, { events } from 'src/services/ga';
import localStore from 'src/services/localStore';
import { USER } from 'src/resources/constants/user';

const FloatSupportView = loadable(() => import('src/components/floatSupportView'));
const BLACK_LIST_URL = [URL.INVEST_CONFIRMATION, URL.LIVE_CHAT, URL.USER_SIGN_UP, URL.FORUM_CHAT];

const ContactSupport = props => {
  const { openContactSupport = false, history, location, onChangeOpen, hideSupportButton } = props;
  const [open, setOpen] = useState(openContactSupport);
  const [isShow, setIsShow] = useState(!BLACK_LIST_URL.some(url => location.pathname.includes(url)));
  const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';

  useEffect(() => {
    let unlisten = history.listen((location) => {
      setIsShow(!BLACK_LIST_URL.some(url => location.pathname.includes(url)));
    });
    return () => {
      unlisten();
    };
  }, []);

  useEffect(() => {
    if(open !== openContactSupport) {
      setOpen(openContactSupport);
      // if(window.FB) {
      //   window.FB.CustomerChat.hide();
      // }
    }
  }, [openContactSupport]);

  const handleClick = (e) => {
    // if(open && window.FB) {
    //   window.FB.CustomerChat.hide();
    // }

    switch (location.pathname) {
      case URL.CRYPTO_LOAN: {
        ga(events.category.borrow, open ? events.action.borrow_help_button_close : events.action.borrow_help_button, email);
        break;
      }
    }

    setOpen(!open);
    onChangeOpen(!open);
  };

  const handleClose = e => {
    switch (location.pathname) {
      case URL.CRYPTO_LOAN: {
        ga(events.category.borrow, events.action.borrow_help_button_hide, email);
        break;
      }
    }

    e.preventDefault();
    hideSupportButton();
  };

  return isShow && (
    <div className={styles.wrapper}>
      <div role="presentation" id="contact-support-button" onClick={handleClick} className={cx(styles.overlay, open && styles.active)} />
      {open && <FloatSupportView onClose={handleClick} />}
      <div className={cx(styles.contactButton, open && styles.open)} role="presentation" onClick={handleClick}>
        <img alt="" src={open ? contactClose : contact} className={styles.callImg}/>
        <div className={styles.close} role="presentation" onClick={handleClose}><img alt="" src={IconClose} /></div>
      </div>
    </div>
  );
};

const mapDispatch = {
  hideSupportButton,
};

export default compose(
  connect(null, mapDispatch),
  withRouter,
)(ContactSupport);
